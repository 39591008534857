import React from 'react'
import * as styles from './index.module.scss'
import { Link } from 'react-router-dom'
import placeholderImage from 'assets/images/user-placeholder.hbd.jpg'

export function Featured({ className, ...props }) {
  return (
    <div className="d-md-flex mt-3 justify-content-around text-center px-3 px-sm-5 px-md-2 px-lg-5" {...props} />
  )
}

Featured.Item = function({
  title,
  imgSrc = placeholderImage,
  links = [],
}) {
  return (
    <div className={styles.featuredItem}>
      <img
        src={imgSrc}
        alt={title}
        className={styles.featuredItemImg}
      />
      <div className="p-2">
        <div className={styles.featuredItemTitle}>
          {title}
        </div>
        {links.map(
          (link, i) => (
            <div key={i}>
              {link}
            </div>
          )
        )}
      </div>
    </div>
  )
}
Featured.Item.displayName = "Featured.Item"

Featured.Item.Link = function(props) {
  if (props.to) {
    return <Link {...props} />
  }
  else if (props.href) {
    return <a {...props} />
  }
  else {
    return <span {...props} />
  }
}
