import React, { useRef } from 'react'
import './blog-post.sass'
import moment from 'moment'
import gql from 'graphql-tag'
import withQueryResult from 'utils/withQueryResult'
import Comments from './Comments'
import Paincrumb from 'components/common/Paincrumb'
import { Link } from 'react-router-dom'
import useRouter from 'utils/useRouter'

const BlogPost = ({ blogPost: { id, title, htmlContent, publishedAt, slug }, showCommentsCount, commentsCount }) =>
  <article className="blog-post">
    <h2 className="blog-h2 mt-2">{title}</h2>
    <div className="published-at mb-4">{moment(publishedAt).format("LL")}</div>
    <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    {showCommentsCount && (
      <Link to={`/blog/${slug}/#comments`} className="link-unstyled">
        {t(`blog-page.comments-count`, { count: commentsCount })}
      </Link>
    )}
  </article>


function _StandAlone(props) {
  const { location: { pathname, hash } } = useRouter()
  const hasScrolledRef = useRef(false)
  const commentsNodeRefCallback = el => {
    if (el && hash === '#comments' && !hasScrolledRef.current) {
      hasScrolledRef.current = true
      el.scrollIntoView()
    }
  }
  return (
    <>
      <Paincrumb>
        <Paincrumb.Item>
          <Link to={`/blog/`}>
            {t(`layout.main-menu.blog`)}
          </Link>
        </Paincrumb.Item>
        <Paincrumb.Item>
          <a href={pathname}>
            {props.blogPost.title}
          </a>
        </Paincrumb.Item>
      </Paincrumb>
      <BlogPost {...props} />
      {props.blogPost.allowComments && (
        <div id="comments" className="pt-4 mt-4" ref={commentsNodeRefCallback}>
          <h3 className="mb-4">{t('blog-post-page.comments.title')}</h3>
          <Comments blogPostId={props.blogPost.id} comments={props.blogPost.comments} />
        </div>
      )}
    </>
  )
}

export const StandAlone = withQueryResult(
  gql`
    query BlogPost($slug: String!) {
      blogPost(slug: $slug) {
        id
        slug
        title
        htmlContent
        publishedAt
        allowComments
        comments {
          id
          author {
            id
            username
            avatarUrl
          }
          content
          date
        }
      }
    }
  `,
  {
    variables: ({ slug }) => ({ slug })
  }
)(_StandAlone)

export default BlogPost
