import React from 'react'
import moment from 'moment'
import userPlaceHolderImage from 'assets/images/user-placeholder.hbd.jpg'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import AddCommentForm from './AddCommentForm'
import useMe from 'utils/useMe'

const FormatCommentText = ({ children }) => (
  children.split("\n").map(
    (line, i, lines) => (
      <React.Fragment key={i}>
        { line }
        { (i < lines.length - 1) ? <br /> : null }
      </React.Fragment>
    )
  )
)

const Comments = ({ blogPostId, comments, style, className }) => {
  const logged = !!useMe()
  return (
    <div style={style} className={className}>
      {
        !!comments.length && (
          <div className="mb-5">
            {
              comments.map(
                comment => (
                  <Comment
                    key={comment.id}
                    comment={comment}
                  />
                )
              )
            }
          </div>
        )
      }
      {logged && <AddCommentForm blogPostId={blogPostId} />}
    </div>
  )
}

function Comment({ comment: { id, author, content, date } }) {
  return (
    <div key={id} className={cn("d-flex participation-comment")}>
      <Link to={`/u/${author.id}/`} className="link-unstyled">
        <img
          src={author.avatarUrl || userPlaceHolderImage}
          alt=""
          className="author-thumb"
        />
      </Link>
      <div style={{ flex: 1 }}>
        <div className="comment-author">
          <Link to={`/u/${author.id}/`} className="link-unstyled">
            <strong>{author.username}</strong>
          </Link>
          {" "}
          <span>{moment(date).format("LLL")}</span>
        </div>
        <div className="comment-caret" />
        <p className="comment-content">
          <FormatCommentText>
            { content }
          </FormatCommentText>
        </p>
      </div>
    </div>
  )
}

export default Comments
