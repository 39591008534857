import { gridBreakpoints } from 'config/sassVariables'
import { useMediaQuery } from 'react-responsive'
import useMe from 'utils/useMe'

function useMobileReaderMode() {
  const touchDevice = useMediaQuery({ query: `(pointer: coarse)` })
  const smWidth = useMediaQuery({ query: `(min-width: ${gridBreakpoints.sm}px)` })
  const smHeight = useMediaQuery({ query: `(min-height: ${gridBreakpoints.sm}px)` })
  const lgWidth = useMediaQuery({ query: `(min-width: ${gridBreakpoints.lg}px)` })
  const lgHeight = useMediaQuery({ query: `(min-height: ${gridBreakpoints.lg}px)` })

  // shitty hack for user alvaro who uses a wacom cintiq, which is detected as a touch device (pointer: coarse)
  // the guy doesn't want the "touch device UX" and wants the Participation page to open like on desktop
  const me = useMe()
  const isAlvaro = !!me && me.slug === 'alvaro-shin'

  if (touchDevice && !isAlvaro) {
    return !lgWidth || !lgHeight
  }
  return !smWidth || !smHeight
}

export default useMobileReaderMode
