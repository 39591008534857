import React, { useMemo, useRef } from 'react'
import { Link } from 'react-router-dom'
import useBreakpoint from 'utils/useBreakpoint'
import { Trans } from 'react-i18next'
import moment from 'moment'
import nl2br from 'utils/nl2br'
import isIn from 'common/fp/isIn'
import cn from 'classnames'
import placeholderImage from 'assets/images/user-placeholder.hbd.jpg'
import lefebulImg from 'assets/images/23hbd/2023/lefebul.jpg'
import clameurImg from 'assets/images/23hbd/2023/clameur.jpg'
import afficheThumbImg from 'assets/images/23hbd/2023/affiche-thumb.jpg'
import * as styles from './index.module.hbd.scss'
import { style } from 'react-toastify'
// import Markdown from 'utils/Markdown'
import Markdown from 'markdown-to-jsx'
import { createPortal } from 'react-dom'
import { Query } from '@apollo/react-components'
import gql from 'graphql-tag'
import { Featured } from './Featured'
import { RandomRead } from './RandomRead'

const now = moment()

function PortaledH1({ target, children }) {
  return createPortal(
    children,
    target
  )
}

function Home({ currentEdition }) {
  const titleRef = useRef()
  const period = useMemo(
    () => {
      const beginDate = moment(currentEdition.beginDate)
      const endDate = moment(currentEdition.endDate)
      if (now.isBefore(beginDate.clone().subtract(8, 'hours'))) return 'before'
      if (now.isBefore(beginDate)) return 'day-before'
      if (now.isBefore(endDate)) return 'during'
      if (now.isBefore(endDate.clone().add(11, 'hours'))) return 'day-after'
      return 'after'
    },
    [currentEdition]
  )
  const randomReadYear = (period === 'day-after' || period === 'after') ? currentEdition.year : (currentEdition.year - 1)
  return (
    <div className={styles.mainContent}>
      <h1 ref={titleRef} />
      <div className="d-flex flex-column flex-md-row align-items-center align-items-md-start">
        <div className="mt-md-5">
          <div className={styles.mascotte} />
        </div>
        <div className={cn("flex-grow-1 py-3 px-sm-4")}>
          {!!titleRef.current && (
            <Markdown
              options={{
                overrides: {
                  h1: { component: PortaledH1, props: { target: titleRef.current }},
                },
              }}
            >
              {t(`flavored:home-page.content`)}
            </Markdown>
          )}
          {period === 'before' && (
            <div className={style.editionDatesInfo}>
              {t(`flavored:home-page.before-content.dates-info`, { startDay: moment(currentEdition.beginDate).date(), endDay: moment(currentEdition.endDate).date() })}
            </div>
          )}
          {period === 'day-before' && (
            <div className={style.editionDatesInfo}>
              {t(`flavored:home-page.day-before-content.dates-info`)}
            </div>
          )}
          {(period |> isIn(['during', 'day-after', 'after'])) && (
            <div className={styles.themeInfo}>
              {
                currentEdition.theme
                  ? <Markdown>{currentEdition.theme}</Markdown>
                  : t(`flavored:home-page.during-content.theme-incoming`)
              }
            </div>
          )}
          <TwitchBlock currentEdition={currentEdition} />
        </div>
      </div>
      <RandomRead year={randomReadYear} />
      {/* <Featured>
        <Featured.Item
          title="Lefebul, illustratrice 2023"
          imgSrc={lefebulImg}
          links={[
            <Featured.Item.Link key={0} style={{ fontSize: "0.9em" }} to="/blog/035884_lefebul-illustratrice-et-marraine-2023/">{t(`global.read-interview`)}</Featured.Item.Link>
          ]}
        />
        <Featured.Item
          title="La Clameur, partenaire 2023"
          imgSrc={clameurImg}
          links={[
            <Featured.Item.Link key={0} style={{ fontSize: "0.9em" }} to="/blog/655546_la-clameur-partenaire-2023/">{t(`global.read-interview`)}</Featured.Item.Link>
          ]}
        />
        <Featured.Item
          title="L'illustration de l'édition 2023"
          imgSrc={afficheThumbImg}
          links={[
            <Featured.Item.Link key={0} style={{ fontSize: "0.9em" }} href="https://d3npuczmg449ex.cloudfront.net/content/affiche-finale-2023.jpg" target="blank">{t(`global.download-edition-poster`)}</Featured.Item.Link>
          ]}
        />
      </Featured> */}
    </div>
  )
}

function TwitchBlock({ currentEdition }) {
  const isLg = useBreakpoint("lg")
  if (currentEdition && currentEdition.customData && currentEdition.customData.twitch && currentEdition.customData.twitch.active) {
    return (
      isLg
        ? (
          <div className="mt-5">
            <div
              className="d-flex justify-content-center mt-5"
              dangerouslySetInnerHTML={{ __html: currentEdition.customData.twitch.content }}
            />
          </div>
        )
        : (
          <div className="mt-5">
            <Trans i18nKey="flavored:home-page.radio-carotte.simple-link">
              Cette année, suivez les 23hBD avec le stream officiel, <a href={currentEdition.customData.twitch.url}>Radio Carotte</a> !
            </Trans>
          </div>
        )
    )
  }
  return null
}

export default Home
