import { useCallback, useContext, useMemo } from 'react'
import { __RouterContext as RouterContext, matchPath } from 'react-router'

export default function useRouter(options) {
  const {
    history,
    location,
  } = useContext(RouterContext)

  if (typeof options === 'string')
    options = { path: options }

  const navigate = useCallback(
    (to, { replace = false } = {}) => {
      if (replace)
        history.replace(to)
      else
        history.push(to)
    },
    [history]
  )

  const match = useMemo(
    () => options ? matchPath(location.pathname, options) : null,
    [JSON.stringify(options), location]
  )

  return {
    history,
    location,
    match,
    navigate
  }
}
